/* Defaults */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background: 'linear-gradient(to bottom right, #2A2929, black 64%)';
  /* overflow: hidden; */
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Primary Variables */
:root {
  --primary: 'green';
  --animate-duration: 1600ms;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(24, 24, 24) inset !important;
  -webkit-text-fill-color: #ffffff !important;
}

.login_container {
  gap: 50px;
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: center;
  border: 1px solid #ffffff;
  border-radius: 12px;
  width: 30%;
}

.header_container {
  position: absolute;
  width: 100%;
  background-color: transparent;
  padding-inline: 150px;
  padding-block: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
}

.plan_container {
  width: 80%;
  margin-top: 7%;
  display: flex;
  flex-direction: column;
  gap: 45px;
  align-items: center;
}

.home_container {
  width: 65%;
  height: 85%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
}

.search_div {
  position: absolute;
  top: 20%;
  display: flex;
  flex-direction: column;
  gap: 100px;
  width: 30%;
  align-items: center;
}

.textArea::placeholder {
  color: rgba(255, 255, 255, 0.636);
}

@media screen and (max-width: 500px) {
  .login_container {
    width: 100%;
    border: none;
    height: 90vh;
    padding: 20px;
  }

  .header_container {
    padding-inline: 24px;
    padding-block: 10px;
  }

  .plan_container {
    width: 100%;
    gap: 15px;
    margin-top: 20%;
  }

  .home_container {
    width: 100%;
    padding: 20px;
  }

  .search_div {
    width: 85%;
    padding: 20px;
  }
}
