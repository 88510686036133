.login_main_div {
  gap: '50px';
  display: 'flex';
  flex-direction: 'column';
  padding: '50px';
  align-items: 'center';
  border: '1px solid #FFFFFF';
  border-radius: '12px';
  width: '50%';
}
